import { type FC, useState } from 'react';
import { Card } from '../../../Card';
import { type TeamMembersQueryParams, useTeamMembersQuery } from '../../../../apiHooks/useTeamMembersQuery';
import { useTeamMembersData } from '../../hooks/useTeamMembersData';
import styled from 'styled-components';
import { Pagination } from '../../../Pagination';
import { Table } from '../../../Table';
import { NamedContentEntryText } from '../../../ContentEntryText/NamedContentEntryText';
import { Icon, IconType } from '../../../Props/Icon/Icon';

const ITEMS_PER_PAGE: number = 10 as const;

export const TeamMembers: FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [teamMembersParams, setTeamMembersParams] = useState<TeamMembersQueryParams>({
    page: {
      skip: 0,
      take: ITEMS_PER_PAGE,
    },
    sort: {
      sortDirection: 1,
      property: 'name',
    },
  });

  const { data: teamMembersData } = useTeamMembersQuery(teamMembersParams);
  const { columns: teamMembersColumns } = useTeamMembersData();

  const handlePageChange = (page: number) => {
    setTeamMembersParams((prev) => ({
      ...prev,
      page: {
        take: ITEMS_PER_PAGE,
        skip: (page - 1) * ITEMS_PER_PAGE,
      },
    }));
    setCurrentPage(page);
  };

  return (
    <Card>
      <TeamMembersSection>
        <SectionHeader>
          <Title>Team Members</Title>
          {!!teamMembersParams.filters?.length && (
            <ActiveFilterPill>
              <Icon width={16} height={16} icon={IconType.FILTER} fill="transparent" />
              <div>
                <NamedContentEntryText refKey="managerTeamBehaviors" container="span" subKey="activeFilter" />
                <b>{teamMembersData.values.length}</b>
              </div>
              <Icon className="remove-filter" width={16} height={16} icon={IconType.TIMES} fill="transparent" />
            </ActiveFilterPill>
          )}
          <ExportCSVButton>
            Export activity log (CSV) <Icon width={16} height={16} icon={IconType.DOWNLOAD} fill="transparent" />
          </ExportCSVButton>
        </SectionHeader>
        <Table data={teamMembersData.values} columns={teamMembersColumns} />
        <Pagination
          pages={Math.ceil(teamMembersData.total / ITEMS_PER_PAGE)}
          currentPage={currentPage}
          perPage={ITEMS_PER_PAGE}
          listSize={teamMembersData?.total}
          onChange={(page) => {
            handlePageChange(page);
          }}
        />
      </TeamMembersSection>
    </Card>
  );
};

const TeamMembersSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 600;
  text-transform: uppercase;
`;

const ExportCSVButton = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: ${({ theme }) => theme.colors.anchor};
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

const ActiveFilterPill = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: ${({ theme }) => theme.colors.insetPanelBackground};
  border: 1px solid ${({ theme }) => theme.colors.tableBorder};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  padding: 4px 8px;
  margin-right: auto;
  > .remove-filter {
    cursor: pointer;
  }
`;
