import type { Column } from '../../Table/Table';
import type { TeamMembersUser } from '../../../lib/types/TeamMembersUser';
import { useMemo } from 'react';
import styled from 'styled-components';
import { AvatarInitials } from '../../AvatarInitials';
import { formatNumberWithCommas } from '../../../lib/numbers/formatNumberWithComma';
import { Icon, IconType } from '../../Props/Icon/Icon';
import { UserOptionsMenu } from '../components/UserOptionsMenu';
import { ProgressBar, ProgressFill } from '../../Activity/components/TrainingCard';

type TeamMembersData = {
  columns: Column<TeamMembersUser>[];
};

export const useTeamMembersData = (): TeamMembersData => {
  return useMemo(
    () => ({
      columns: [
        {
          header: 'Name',
          valueSelector: (user: TeamMembersUser) => (
            <UserName>
              {user.avatar ? (
                <UserImg src={`data:image/png;base64,${user.avatar}`} />
              ) : (
                <AvatarInitials name={user.name || ''} id={user.name ?? ''} size="32px" />
              )}
              {user.name}
            </UserName>
          ),
        },
        {
          header: 'Job Title',
          valueSelector: (user: TeamMembersUser) => user.jobTitle,
        },
        {
          header: 'Department',
          valueSelector: (user: TeamMembersUser) => user.departmentId,
        },
        {
          header: 'Cyber IQ',
          valueSelector: (user: TeamMembersUser) => (
            <UserPoints className="user-points">
              <Icon width={20} height={20} icon={IconType.COIN} /> {formatNumberWithCommas(user.cyberIqPoints)}
            </UserPoints>
          ),
        },
        {
          header: 'Training',
          valueSelector: (user: TeamMembersUser) => {
            const progress = user.trainings ? (user.trainings.completed / user.trainings.assigned) * 100 : 0;
            return (
              <ProgressBar>
                <ProgressFill progress={progress} />
              </ProgressBar>
            );
          },
          width: '150px',
        },
        {
          header: '',
          valueSelector: () => <UserOptionsMenu />,
          width: '36px',
        },
      ],
    }),
    [],
  );
};

const UserName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.heading};
`;

const UserImg = styled.img`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

const UserPoints = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  display: flex;
  align-items: center;
  gap: 8px;
`;
