import type { FC } from 'react';
import styled from 'styled-components';
import { BehaviorBarMap } from '../BehaviorBar/BehaviorBar';
import { NamedContentEntryText } from '../../../ContentEntryText/NamedContentEntryText';
import { BehaviorType } from '../../enum/BehaviorType';
import { useMediaQuery } from '../../../../lib/hooks/useMediaQuery';

type BehaviorTypeHeaderProps = {
  type: BehaviorType;
};

export const BehaviorTypeHeader: FC<BehaviorTypeHeaderProps> = ({ type }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <BehaviorTableHeaderContainer type={type}>
      {type !== BehaviorType.STRONG && !isMobile && <Circle $background={BehaviorBarMap[type].backgroundColor} />}
      {isMobile && <Circle $background={BehaviorBarMap[type].backgroundColor} />}
      <BehaviorTitle>
        {/* TODO: translation */}
        {type === BehaviorType.NEUTRAL ? (
          'Neutral'
        ) : (
          <NamedContentEntryText
            container={'span'}
            refKey="managerTeamBehaviors"
            subKey={type === BehaviorType.WEAK ? 'weakBehavior' : 'strongBehavior'}
          />
        )}
      </BehaviorTitle>
      {type === BehaviorType.STRONG && !isMobile && <Circle $background={BehaviorBarMap[type].backgroundColor} />}
    </BehaviorTableHeaderContainer>
  );
};

const BehaviorTableHeaderContainer = styled.div<{ type: BehaviorType }>`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: ${({ type }) => (type === BehaviorType.WEAK ? 'flex-start' : 'flex-end')};
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

const Circle = styled.div<{ $background: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ $background }) => $background};
`;

const BehaviorTitle = styled.div`
  max-width: 150px;
  white-space: wrap;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
