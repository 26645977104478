import { type FC, useState } from 'react';
import { Icon, IconType } from '../../Props/Icon/Icon';
import styled from 'styled-components';
import { useClickOutside } from '../../../lib/hooks/useOnClickOutside';

export const UserOptionsMenu: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const clickOutsideRef = useClickOutside<HTMLDivElement>(() => setIsMenuOpen(false));

  const onOptionClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <OptionsMenuContainer ref={clickOutsideRef}>
      <div onClick={() => setIsMenuOpen((prev) => !prev)}>
        <Icon icon={IconType.DOTS_MENU_ICON} width={16} height={16} />
      </div>
      <Menu $isHidden={!isMenuOpen}>
        <MenuItem onClick={onOptionClick}>Flag as not part of the team</MenuItem>
        <MenuItem onClick={onOptionClick}>View as team member</MenuItem>
      </Menu>
    </OptionsMenuContainer>
  );
};

const OptionsMenuContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    cursor: pointer;
  }
`;

const Menu = styled.div<{ $isHidden: boolean }>`
  min-width: 250px;
  position: absolute;
  left: calc(100% + 12px);
  top: 0;
  opacity: ${({ $isHidden: isHidden }) => (isHidden ? 0 : 1)};
  transform: ${({ $isHidden: isHidden }) => (isHidden ? 'translate(-10px, -10%)' : 'translate(0, -10%)')};
  transition:
    opacity 0.3s,
    transform 0.3s;
  pointer-events: ${({ $isHidden: isHidden }) => (isHidden ? 'none' : 'auto')};
  z-index: 99;
  background-color: ${({ theme }) => theme.colors.panelBackground};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  box-shadow: ${({ theme }) => theme.shadow.light};
  border: 1px solid ${({ theme }) => theme.colors.tableBorder};
`;

const MenuItem = styled.div`
  width: 100%;
  padding: 8px 16px;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  &:hover {
    background-color: ${({ theme }) => theme.colors.insetPanelBackground};
  }
  &:first-child {
    border-top-left-radius: ${({ theme }) => theme.borderRadius.normal};
    border-top-right-radius: ${({ theme }) => theme.borderRadius.normal};
  }
  &:last-child {
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius.normal};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.normal};
  }
`;
