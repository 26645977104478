import { useLPQuery } from '../lib/hooks/useLPQuery';
import type { ScoreBreakdownCategories } from './useScoreBreakdownByUserQuery';

export const useLatestActivitiesQuery = (params?: LatestActivitiesQueryParams) => {
  return useLPQuery<GetLatestActivitiesResponse>(
    ['activities-learner', params],
    '/activities/latest',
    {},
    undefined,
    params ? { method: 'POST', json: params } : undefined,
  );
};

export type GetLatestActivitiesResponse = LatestActivitiesResponse | LatestActivitiesResponseWithParams;

export type LatestActivitiesResponse = {
  learnerId: string;
  totalScore: number;
  learnerActivities: LearnerActivity[];
};

export type LatestActivitiesResponseWithParams = {
  skip: number;
  take: number;
  total: number;
  totalScore: number;
  values: LearnerActivity[];
};

export type LearnerActivity = {
  actionScoreType: ActionScoreTypes;
  createdTime: string;
  score?: number;
  name: string;
  scoreBreakdown: ScoreBreakdownCategories;
};

export type LatestActivitiesQueryParams = {
  page?: {
    skip: number;
    take: number;
  };
  sort?: {
    property: string;
    sortDirection: string;
  };
  scoreBreakdowns?: ScoreBreakdownCategories[];
};

export enum ActionScoreTypes {
  fullCampaignCompleted = 'fullCampaignCompleted',
  campaignCompletedWithHighEngagement = 'campaignCompletedWithHighEngagement',
  campaignCompletedWithHighKnowledge = 'campaignCompletedWithHighKnowledge',
  ratingLeft = 'ratingLeft',
  phishingSimulationReported = 'phishingSimulationReported',
  phishingSimulationClicked = 'phishingSimulationClicked',
  defenderTagGranted = 'defenderTagGranted',
  linkRepeatedlyClicked = 'linkRepeatedlyClicked',
  optionalTrainingCompleted = 'optionalTrainingCompleted',
  trainingIncompleteAfterDueDate = 'trainingIncompleteAfterDueDate',
  ratingFailed = 'ratingFailed',
  campaignCompletedWithLowKnowledge = 'campaignCompletedWithLowKnowledge',
  campaignCompletedWithLowEngagement = 'campaignCompletedWithLowEngagement',
}

export const trackedActionsScoreTypesSet = new Set(Object.values(ActionScoreTypes));
