import styled from 'styled-components';
import { type FC, useState } from 'react';
import { Icon, IconType } from '../Props/Icon/Icon';
import { Coin } from '../Props';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';
import {
  type AggregatedScoreBreakdown,
  type CampaignAction,
  CampaignActionType,
  ScoreBreakdownCategories,
  type ScoreBreakdownContentEntryKey,
  useScoreBreakdownByUserQuery,
} from '../../apiHooks/useScoreBreakdownByUserQuery';
import { StatusIcon, StatusIconType } from '../Props/StatusIcon/StatusIcon';
import { NoUserDataCard } from './components/NoUserDataCard';
import { Tooltip } from '../Tooltip';
import { ActionTooltip } from './components/ActionTooltip';
import { TitleContainer } from '../Card/Card';
import { Spread } from '../utility/Spread';
import { ViewActivityLogTooltip } from './components/ViewActivityLogTooltip';
import { ActivityLogModal } from './components/ActivityLogModal';
import { ScoreBreakdownTooltip } from './components/ScoreBreakdownTooltip';
import type { ActivityOverviewWidgetParams } from '../Activity/ActivityOverviewWidget';
import { GroupingType, TimeRange } from '../../apiHooks/useUserScoreTimelineQuery';

type ActivityLogModalProps = {
  isOpen: boolean;
  params?: ActivityOverviewWidgetParams;
};

export const ScoreBreakdown: FC = () => {
  const scoreBreakdownByUserResponse = useScoreBreakdownByUserQuery();
  const [expandedIndices, setExpandedIndices] = useState<number[]>([]);
  const [modalProps, setModalProps] = useState<ActivityLogModalProps>({
    isOpen: false,
    params: undefined,
  });

  const campaignActions: CampaignAction[] = scoreBreakdownByUserResponse.data?.campaignActions ?? [];
  const scoreBreakdownAggregations: AggregatedScoreBreakdown[] =
    scoreBreakdownByUserResponse.data?.scoreBreakdownAggregations ?? [];

  const toggleExpand = (index: number) => {
    setExpandedIndices((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
  };

  const getContentEntryKeyByScoreBreakdown = (item: AggregatedScoreBreakdown): ScoreBreakdownContentEntryKey => {
    // TODO: It would be best if item.scoreBreakdown were capitalized directly from the backend
    return `scoreBreakdown${item.scoreBreakdown.charAt(0).toUpperCase() + item.scoreBreakdown.slice(1)}` as ScoreBreakdownContentEntryKey;
  };

  const handleViewFullLog = (item: AggregatedScoreBreakdown) => {
    setModalProps({
      isOpen: true,
      params: {
        scoreBreakdowns: [item.scoreBreakdown],
        groupingType: GroupingType.MONTH,
        timeRange: TimeRange.ONE_YEAR,
      },
    });
  };

  return (
    <Container>
      <TitleContainer>
        <Spread>
          <Title>
            <NamedContentEntryText container={'span'} refKey="sectionTitles" subKey="scoreBreakdown" />
            <NamedContentEntryText container={'label'} refKey="sectionTitles" subKey="last30Days" />
          </Title>
          <ViewActivityLogButton onClick={() => setModalProps({ isOpen: true, params: undefined })}>
            <NamedContentEntryText container={'span'} refKey="scoreBreakdownTooltips" subKey="viewActivityLog" />
            <Tooltip content={<ViewActivityLogTooltip />}>
              <Icon icon={IconType.INFO_TOOLTIP} width={16} height={16} fill="transparent" />
            </Tooltip>
          </ViewActivityLogButton>
        </Spread>
      </TitleContainer>
      {!!scoreBreakdownAggregations.length &&
        scoreBreakdownAggregations.map((item, index) => {
          const shouldShowActions =
            item.scoreBreakdown === ScoreBreakdownCategories.PHISHING_CLICKS ||
            item.scoreBreakdown === ScoreBreakdownCategories.PHISHING_REPORTS;

          return (
            <ScoreItem key={item.scoreBreakdown}>
              <ScoreItemIcon>
                <img src={item.assetsUrl} alt={item.scoreBreakdown} />
              </ScoreItemIcon>
              <ScoreItemContent>
                <ScoreItemHeader onClick={() => toggleExpand(index)}>
                  <ToggleArrow>
                    <Icon
                      icon={expandedIndices.includes(index) ? IconType.CHEVRON_DOWN : IconType.CHEVRON_RIGHT}
                      fill={'transparent'}
                    />
                  </ToggleArrow>
                  <ScoreItemHeaderTitle>
                    <NamedContentEntryText
                      refKey={getContentEntryKeyByScoreBreakdown(item)}
                      container={'span'}
                      subKey={'heading'}
                    />
                  </ScoreItemHeaderTitle>
                </ScoreItemHeader>
                <ScoreItemDescription $isVisible={expandedIndices.includes(index)}>
                  <NamedContentEntryText
                    refKey={getContentEntryKeyByScoreBreakdown(item)}
                    container={'span'}
                    subKey={'body'}
                  />
                  {campaignActions.length > 0 && shouldShowActions && (
                    <ScoreItemFooter>
                      <Icon className="rtl-flip" icon={IconType.CORNER_DOWN_RIGHT_ARROW} height={16} width={16} />
                      <ScoreItemPillsContainer>
                        <ScoreItemPillsIconsContainer>
                          {campaignActions.map((action) => (
                            <Tooltip
                              content={<ActionTooltip actionResult={action.result} date={action.date} />}
                              key={action.campaignId}
                            >
                              <StatusIcon
                                icon={
                                  action.result === CampaignActionType.SUCCESS
                                    ? StatusIconType.SUCCESS
                                    : action.result === CampaignActionType.NEUTRAL
                                      ? StatusIconType.NEUTRAL
                                      : StatusIconType.FAIL
                                }
                                height={16}
                                width={16}
                              />
                            </Tooltip>
                          ))}
                        </ScoreItemPillsIconsContainer>
                        <NamedContentEntryText refKey={'scoreBreakdownTooltips'} container={'span'} subKey={'result'} />
                      </ScoreItemPillsContainer>
                    </ScoreItemFooter>
                  )}
                </ScoreItemDescription>
              </ScoreItemContent>
              <ScoreItemCoin>
                <Tooltip interactive content={<ScoreBreakdownTooltip item={item} onClick={handleViewFullLog} />}>
                  <Coin className="score" value={item.score} />
                </Tooltip>
              </ScoreItemCoin>
            </ScoreItem>
          );
        })}
      {!scoreBreakdownAggregations.length && <NoUserDataCard />}
      <ActivityLogModal
        isOpen={modalProps.isOpen}
        onClose={() => setModalProps({ isOpen: false, params: undefined })}
        params={modalProps.params}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ScoreItem = styled.div`
  display: flex;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    gap: 16px;
  }
`;

const ScoreItemIcon = styled.div`
  display: flex;
  width: 64px;
  height: 64px;
  max-width: 64px;
  max-height: 64px;
  background: #f7f8fa;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;

    img {
      width: 32px;
      height: 32px;
    }
  }
`;

const ScoreItemContent = styled.div`
  width: 100%;
  margin: 0 20px;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

const ScoreItemHeader = styled.div`
  display: flex;
`;

const ScoreItemHeaderTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.heading};
  font-weight: 600;
  letter-spacing: 0.28px;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 10px;
  }
`;

const ScoreItemCoin = styled.div`
  display: flex;
  align-items: start;

  .score {
    cursor: pointer;
  }
`;

const ToggleArrow = styled.div`
  cursor: pointer;
  display: none;

  @media (max-width: 768px) {
    display: block;
    margin-right: 16px;
  }
`;

const ScoreItemDescription = styled.div<{ $isVisible: boolean }>`
  margin-top: 8px;
  font-size: ${({ theme }) => theme.fontSizes.normal};

  @media (max-width: 768px) {
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  }
`;

const ScoreItemFooter = styled.div`
  margin-top: 4px;
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  align-items: center;
`;

const ScoreItemPillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #d5daee;
  background: #eef1f9;
  border-radius: 16px;
  padding: 4px 8px 4px 4px;
  gap: 6px;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: 500;
  /* This margin is to align the pills with the icon */
  margin-top: 4px;
`;

const ScoreItemPillsIconsContainer = styled.div`
  display: flex;
  gap: 2px;
`;

const ViewActivityLogButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.anchor};
  text-decoration: underline;
  font-size: ${({ theme }) => theme.fontSizes.normal};
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  > label {
    text-transform: none;
    color: ${({ theme }) => theme.colors.normal};
    font-size: ${({ theme }) => theme.fontSizes.normal};
    font-weight: 400;
  }
`;
