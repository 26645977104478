import { TeamBehaviorTypes } from '../../../lib/enums/teamBehaviorsTypes';
import TrainingCompletedIcon from '../assets/AllTrainingsCompleted.svg';
import CyberSecurityKnoaledgeIcon from '../assets/BuildKnowledge.svg';
import HighEngagmentIcon from '../assets/HighEngagement.svg';
import SimulatedPhishingIcon from '../assets/NoClickPhishing.svg';
import ReportPhishingIcon from '../assets/ReportPhishing.svg';
import { ScoreBreakdownType } from '../../../lib/types/TeamBehavior';
// import DefendAttacksIcon from '../assets/DefendPhishing.svg';
// import ExternalStorageIcon from '../assets/NoExternalUSB.svg';
// import SecureBrowsingIcon from '../assets/SecureDevice.svg';
// import MalwareInfectionIcon from '../assets/MalwareInfection.svg';
// import PasswordManagerIcon from '../assets/PasswordManager.svg';
// import LatestUpdatesIcon from '../assets/LatestUpdates.svg';
// import MaintainSecureDeviceIcon from '../assets/SecureDevice.svg';
// import SensitiveDataIcon from '../assets/SensitiveData.svg';

const getTeamBehaviorDetails = (type: TeamBehaviorTypes | ScoreBreakdownType) => {
  switch (type) {
    case ScoreBreakdownType.Completion:
      return {
        icon: <TrainingCompletedIcon />,
        displayName: 'Complete all your cybersecurity training',
        // TODO: translation once available
        // displayName: <NamedContentEntryText container={'span'} refKey="managerTeamBehaviors" subKey="behaviors" />,
      };
    case ScoreBreakdownType.Knowledge:
      return {
        icon: <CyberSecurityKnoaledgeIcon />,
        displayName: 'Build your cybersecurity knowledge',
        // TODO: translation once available
        // displayName: <NamedContentEntryText container={'span'} refKey="managerTeamBehaviors" subKey="behaviors" />,
      };
    case ScoreBreakdownType.Engagement:
      return { icon: <HighEngagmentIcon />, displayName: 'Maintain high engagement' };
    case ScoreBreakdownType.PhishingClicks:
      return { icon: <SimulatedPhishingIcon />, displayName: 'Do not click on simulated phishing' };
    case ScoreBreakdownType.PhishingReports:
      return { icon: <ReportPhishingIcon />, displayName: 'Report simulated phishing' };

    // TODO: only 5 behaviors supported on first iteration from BE, uncomment this when support from BE available

    // case TeamBehaviorTypes.DefendAttacks:
    //   return <DefendAttacksIcon />;
    // case TeamBehaviorTypes.ExternalStorage:
    //   return <ExternalStorageIcon />;
    // case TeamBehaviorTypes.SecureBrowsing:
    //   return <SecureBrowsingIcon />;
    // case TeamBehaviorTypes.MalwareInfection:
    //   return <MalwareInfectionIcon />;
    // case TeamBehaviorTypes.PasswordManager:
    //   return <PasswordManagerIcon />;
    // case TeamBehaviorTypes.LatestUpdates:
    //   return <LatestUpdatesIcon />;
    // case TeamBehaviorTypes.MaintainSecureDevice:
    //   return <MaintainSecureDeviceIcon />;
    // case TeamBehaviorTypes.SensitiveData:
    //   return <SensitiveDataIcon />;
    default:
      return null;
  }
};
export default getTeamBehaviorDetails;
