import { type FC, useCallback } from 'react';
import { Icon, IconType } from '../../Props/Icon/Icon';
import styled from 'styled-components';

type PaginationControlsProps = {
  pages: number;
  currentPage: number;
  onChange: (v: number) => void;
};

export const PaginationControls: FC<PaginationControlsProps> = ({
  pages,
  currentPage,
  onChange,
}: PaginationControlsProps) => {
  const handleBack = useCallback(() => {
    if (currentPage > 1) {
      onChange(currentPage - 1);
    }
  }, [currentPage, onChange]);

  const handleForward = useCallback(() => {
    if (currentPage < pages) {
      onChange(currentPage + 1);
    }
  }, [currentPage, onChange, pages]);

  return (
    <PaginationControlsContainer>
      <span>Page</span>
      <NavButton onClick={handleBack} $disabled={currentPage <= 1}>
        <Icon className="rotate" width={20} height={20} icon={IconType.CHEVRON_RIGHT} fill={'transparent'} />
      </NavButton>
      <CurrentPageIndicator>{currentPage}</CurrentPageIndicator>
      <TotalPagesIndicator>of {pages}</TotalPagesIndicator>
      <NavButton onClick={handleForward} $disabled={currentPage >= pages}>
        <Icon width={20} height={20} icon={IconType.CHEVRON_RIGHT} fill={'transparent'} />
      </NavButton>
    </PaginationControlsContainer>
  );
};

const PaginationControlsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const NavButton = styled.div<{ $disabled?: boolean }>`
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.colors.pageBackground};
  cursor: pointer;
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  transition: all 250ms ease-in-out;
  svg path {
    stroke: ${({ theme, $disabled }) => ($disabled ? theme.colors.normal : theme.colors.primary)};
  }
  .rotate {
    transform: rotate(180deg);
  }
`;

const CurrentPageIndicator = styled.span`
  padding: 0 16px;
`;

const TotalPagesIndicator = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;
