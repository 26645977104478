import styled from 'styled-components';
import { type FC } from 'react';
import { Icon, IconType } from '../Props/Icon/Icon';
import { useMeQuery } from '../../apiHooks/useMeQuery';
import { Card } from '../Card';
import { useTeamCyberIQLevelsQuery } from '../../apiHooks/useTeamCyberIQLevelsQuery';
import { useTeamSecuritySnapshotQuery } from '../../apiHooks/useTeamSecuritySnapshotQuery';
import { Tooltip } from '../Tooltip';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';
import { LevelsBreakdownDetails } from './LevelsBreakdownDetails';
import { useMediaQuery } from '../../lib/hooks/useMediaQuery';
import { UserLevelDetails } from '../../lib/enums/userLevels';
import { MAX_CYBER_IQ_POINTS } from '../../consts/cyberIQ';

export const TeamSecuritySnapshotUI: FC = () => {
  let currentIQPoints = 0;
  let teamIqPointsProgress = 0;
  let organizationIqPointsProgress = 0;

  const securitySnapshotQuery = useTeamSecuritySnapshotQuery();
  const cyberIqLevelsQuery = useTeamCyberIQLevelsQuery();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const meQuery = useMeQuery();

  if (securitySnapshotQuery.data && meQuery.data && cyberIqLevelsQuery.data) {
    const securitySnapshotData = securitySnapshotQuery.data;

    currentIQPoints = securitySnapshotData.teamCyberIq.cyberIqScore;
    teamIqPointsProgress = (securitySnapshotData.teamCyberIq.cyberIqScore / MAX_CYBER_IQ_POINTS) * 100;
    organizationIqPointsProgress = (securitySnapshotData.organisationCyberIq.cyberIqScore / MAX_CYBER_IQ_POINTS) * 100;
  }

  const Benchmark = (value?: string) => {
    return (
      <BenchmarkSection>
        <Circle />
        <span>
          Benchmark org: <BenchmarkValue>{value || 'N/A'}</BenchmarkValue>
        </span>
      </BenchmarkSection>
    );
  };

  const TooltipInfo = (type: string) => {
    const getText = (type: string) => {
      if (type === 'score') {
        return (
          <NamedContentEntryText refKey="managerTeamSecuritySnapshot" subKey="cyberiqScoreTooltip" container="span" />
        );
      }
      if (type === 'level') {
        return (
          <NamedContentEntryText container={'span'} refKey="managerTeamSecuritySnapshot" subKey="cyberiqLevelTooltip" />
        );
      }
    };

    return <TooltipContainer>{getText(type)}</TooltipContainer>;
  };

  const getLevelProgressBarWithSteps = () => {
    return (
      <ProgressBarWrapper>
        <ProgressBar>
          {securitySnapshotQuery.data.teamCyberIq.cyberIqLevelBrackets.map((level, index) => {
            const userLevelDetailsMap = UserLevelDetails[level.cyberIqLevel];
            if (level.learnerPercentage > 0) {
              return (
                <ProgressFill
                  key={index}
                  progress={level.learnerPercentage}
                  backgroundColor={userLevelDetailsMap.color}
                />
              );
            }
          })}
        </ProgressBar>
      </ProgressBarWrapper>
    );
  };

  return (
    <Card title={<NamedContentEntryText container={'span'} refKey="managerSectionTitles" subKey="securitySnapshot" />}>
      <CardContainer>
        <CategoryContainer>
          <TitleContainer>
            <NamedContentEntryText container={'span'} refKey="managerTeamSecuritySnapshot" subKey="cyberiqScore" />
            <Tooltip offset={15} content={TooltipInfo('score')} placement="top">
              <Icon icon={IconType.INFO_TOOLTIP} width={16} height={16} fill="transparent" />
            </Tooltip>
          </TitleContainer>
          <SummaryContainer>
            <Section>
              <Icon icon={IconType.COIN} width={32} height={32} />
              <span>
                <SectionBoldValue>{currentIQPoints}</SectionBoldValue> / {MAX_CYBER_IQ_POINTS}
              </span>
            </Section>
            {Benchmark(securitySnapshotQuery.data.organisationCyberIq.cyberIqScore.toLocaleString())}
          </SummaryContainer>
          <ProgressBarWrapper>
            <ProgressBar>
              <ProgressFill progress={teamIqPointsProgress} />
              <ProbressBarBenchmark value={organizationIqPointsProgress} />
            </ProgressBar>
          </ProgressBarWrapper>
        </CategoryContainer>
        <CategoryContainer>
          <TitleContainer>
            <NamedContentEntryText container={'span'} refKey="managerTeamSecuritySnapshot" subKey="cyberiqLevel" />
            <Tooltip offset={15} content={TooltipInfo('level')} placement="top">
              <Icon icon={IconType.INFO_TOOLTIP} width={16} height={16} fill="transparent" />
            </Tooltip>
          </TitleContainer>
          <SummaryContainer>
            <Section>
              <SectionBoldValue>{securitySnapshotQuery.data.teamCyberIq.cyberIQLevelAverage}</SectionBoldValue>
            </Section>
            {Benchmark(securitySnapshotQuery.data.organisationCyberIq.cyberIQLevelAverage.toLocaleString())}
          </SummaryContainer>
          <ProgressContainer>
            {isMobile ? (
              getLevelProgressBarWithSteps()
            ) : (
              <Tooltip
                offset={15}
                content={
                  <LevelsBreakdownDetails details={securitySnapshotQuery.data.teamCyberIq.cyberIqLevelBrackets} />
                }
                placement="bottom"
              >
                {getLevelProgressBarWithSteps()}
              </Tooltip>
            )}
          </ProgressContainer>
        </CategoryContainer>
      </CardContainer>
    </Card>
  );
};

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16;
  }
`;

const ProgressBarWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: visible;
`;

const ProgressBar = styled.div<{ backgroundColor?: string }>`
  display: flex;
  gap: 4px;
  flex-grow: 1;
  height: 12px;
  border: 1px solid ${({ theme }) => theme.colors.tableBorder};
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.panelBackground};
  overflow: hidden;
  @media (max-width: 768px) {
    height: 16px;
  }
`;

const ProgressFill = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'progress' && prop !== 'backgroundColor',
})<{ progress: number; backgroundColor?: string }>`
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  background: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '#3c70f5')};
  transition: width 0.3s ease-in-out;
`;

const ProbressBarBenchmark = styled.div<{ value: number }>`
  width: 4px;
  height: 16px;
  border-radius: 20px;
  position: absolute;
  left: ${({ value }) => value}%;
  top: 50%;
  transform: translateY(-50%);
  background: #596180;
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  @media (max-width: 768px) {
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProgressContainer = styled.div`
  grid-column: 1 / span 2;
  grid-row: 4;

  @media (max-width: 768px) {
    grid-column: 1 / span 2;
    grid-row: 5;
    margin-top: 10px;
  }
`;

const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Section = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const SectionBoldValue = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.strong};
  font-size: ${({ theme }) => theme.fontSizes.extraLarge};
`;

const BenchmarkSection = styled(Section)`
  font-size: ${({ theme }) => theme.fontSizes.small};
  gap: 6px;
  align-items: center;
`;

const BenchmarkValue = styled.span`
  font-weight: 600;
`;

const TooltipContainer = styled.div`
  padding: 10px;
  width: 338px;
  background-color: ${({ theme }) => theme.colors.trainingCardBackground};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  box-shadow: #0c0c0d1a 2px 2px 8px;
`;

const Circle = styled.div`
  background-color: #596180;
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;
