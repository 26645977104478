import styled, { useTheme } from 'styled-components';
import { type FC, useEffect } from 'react';
import { PaginationControls } from './components/PaginationControls';
import { Spinner } from '../Spinner/Spinner';

type PaginationProps = {
  listSize: number;
  currentPage: number;
  perPage: number;
  pages: number;
  onChange: (page: number) => void;
  pageSizeOptions?: number[];
  onPageSizeChange?: (pageSize: number) => void;
  loading?: boolean | undefined;
};

export const Pagination: FC<PaginationProps> = ({
  pages,
  listSize,
  currentPage,
  perPage,
  onChange,
  loading,
}: PaginationProps) => {
  const theme = useTheme();

  useEffect(() => {
    if (currentPage === 0) {
      onChange(1);
    }
  }, [currentPage, onChange]);

  if (listSize === 0) {
    return null;
  }

  if (currentPage < 1 || currentPage > pages || perPage < 1 || listSize < 0) {
    console.error('Invalid values supplied', { currentPage, pages, perPage, listSize });
    return null;
  }

  return (
    <PaginationContainer>
      {loading ? (
        <StyledSpinner size="24px" color={theme.colors.primary} />
      ) : (
        <>
          <PaginationInfo>
            Showing {perPage < listSize ? perPage : listSize} out of {listSize}
          </PaginationInfo>
          <PaginationControls pages={pages} currentPage={currentPage} onChange={onChange} />
        </>
      )}
    </PaginationContainer>
  );
};

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  user-select: none;
`;

const PaginationInfo = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: ${({ theme }) => theme.colors.normal};
  font-weight: 400;
`;

const StyledSpinner = styled(Spinner)`
  margin: 0 auto;
`;
